import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useLocalStorage } from 'react-use';

const PRODUCTION_API_URL = 'https://admin.rameelo.com/api';
const DEVELOPMENT_API_URL = 'https://admin.rameelo.com/api';

function useApiUrl() {
  const [selectedEnvironment, setSelectedEnvironment, clearEnvironment] =
    useLocalStorage<string>('environment', undefined);

  if (selectedEnvironment === 'production') {
    return PRODUCTION_API_URL;
  }

  return DEVELOPMENT_API_URL;
}

type Event = {
  id: string;
  title: string;
  location: string;
}

export function useEventsQuery() {
  const API_URL = useApiUrl();

  return useQuery([`ALL_EVENTS`], async () => {
    const events = await axios.get<Event[]>(`${API_URL}/checkin/events`)

    //HACK: only show individual events, not combo
    return events.data.filter((event) => event.title !== '2-Event - Combo');
  });
}

type Order = {
  memberName: string;
  phoneNumber: string;
  createdDate: Date;
  eventName: string;
  num_of_GA_tickets: number;
  num_of_GOLD_tickets: number;
  num_of_DIAMOND_tickets: number;
  when_checked_in?: Date
}

// sync call for more deterministic behavior
export function useOrderDetails(eventId: string) {
  const API_URL = useApiUrl();

  return useMutation(
    [`ORDER_DETAILS`],
    async (orderId: string) => {
      const response = await axios.get<Order>(`${API_URL}/checkin/order-details?event_id=${eventId}&order_id=${orderId}`)
      return response.data;
    },
    {}
  );
}

export const failureSound = new Audio('https://flowy.sfo2.cdn.digitaloceanspaces.com/sound-effects/im-gonna-break-my-monitor-i-swear-audiotrimmer.mp3');
export const successSound = new Audio('https://flowy.sfo2.cdn.digitaloceanspaces.com/sound-effects/success.mp3');

// check in react query mutation
export function useCheckIn(eventId: string) {
  const API_URL = useApiUrl();

  const queryClient = useQueryClient();

  return useMutation(
    [`CHECK_IN`],
    async (orderId: string) => {
      const response = await axios.post<{
        success: boolean;
        message: string;
      }>(`${API_URL}/checkin/${eventId}/${orderId}`);

      return response.data;
    },
    {
    }
  );
}
