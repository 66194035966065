import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'antd/dist/antd.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import Login from './components/Login';
import { useLocalStorage } from 'react-use';
import { RecoilRoot } from 'recoil';
import toast, { Toaster } from 'react-hot-toast';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FiChevronRight } from 'react-icons/fi';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <Routes>
            {/* route for admins who are checking things in*/}
            <Route path="/" element={<ProtectedRoute><App /></ProtectedRoute>} />
            <Route path="/login" element={<Login />} />
          </Routes>
          <Toaster position="bottom-center" reverseOrder={false} />
        </RecoilRoot>

        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const [authPassword, setAuthPassword, clearAuth] =
    useLocalStorage<string>('authPassword');

  const navigate = useNavigate();

  useEffect(() => {
    // not authenticated
    if (authPassword !== 'rameeloBeast') {
      // route user to login page
      navigate('/login');
    }
  }, [authPassword, navigate]);

  if (authPassword !== 'rameeloBeast') {
    return <>not authenticated...going to login</>;
  }

  return (
    <>
      {children}
    </>
  );
}
