import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Divider, Modal, Radio, RadioChangeEvent, Spin, Switch, Tag } from 'antd';
import { BiCurrentLocation, BiSolidDiamond } from "react-icons/bi";
import {
  failureSound,
  successSound,
  useCheckIn,
  useEventsQuery, useOrderDetails,
} from '../controller/reactQuery';
import { QrScanner } from '@yudiel/react-qr-scanner';
import toast from 'react-hot-toast';
import moment from 'moment';

// layer to manage event selection HOC
// this is arjun doing work, not rohan :)
function App() {
  const [selectedEventId, setSelectedEventId] =
    useState<string | undefined>(undefined);

  const { data: events, isLoading, isError } = useEventsQuery();

  const onChangeSelectedEvent = useCallback(
    (e: RadioChangeEvent) => {
      setSelectedEventId(e.target.value);
    },
    [setSelectedEventId]
  );

  const selectedEvent = useMemo(
    () =>
      events?.find((event) => event.id === selectedEventId),
    [events, selectedEventId]
  );

  if (isError) {
    return (
      <div>
        <h1>Something went wrong loading events.</h1>
      </div>
    );
  }
  else if (events?.length === 0 || isLoading) {
    return (<div className="flex flex-col items-center justify-center h-screen">
      <Spin />
      <span className="text-slate-400 text-md uppercase">
        No events found yet. Loading events...
      </span>
    </div>);
  }
  else if (!selectedEventId) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <span className="text-2xl">
          Choose Event:
        </span>
        <Radio.Group
          onChange={onChangeSelectedEvent}
          defaultValue={selectedEventId}
          value={selectedEventId}
          optionType={'default'}
        >
          <div className="flex flex-col p-10 gap-10">
            {events?.map((event) => (
              <Radio.Button
                key={event.id}
                value={event.id}
                className={`${selectedEventId === event.id && 'font-bold text-xxl'
                  } text-slate-400 text-md uppercase`}
              >
                {event.location} | {event.title}
              </Radio.Button>
            ))}
          </div>
        </Radio.Group>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-1 h-screen p-3">
      <CheckInKiosk selectedEventId={selectedEventId} />

      <Divider />

      <span className="text-slate-400 text-md uppercase">
        {selectedEvent?.location}
      </span>

      <button
        className="text-xs text-slate-300 underline text-left"
        onClick={() => setSelectedEventId(undefined)}
      >
        click here to change event
      </button>
    </div>
  );
}


const PARSE_FORMAT = 'M/D/YYYY, H:mm:ss A';

function CheckInKiosk({ selectedEventId }: { selectedEventId: string }) {
  const [showQRReader, setShowQRReader] = useState<boolean>(false);
  const [selectedOrderUuid, setSelectedOrderUuid] = useState<string | undefined>(
    undefined
  );

  const { mutateAsync: getOrder, isLoading: isLoadingOrderDetails, data: order } = useOrderDetails(selectedEventId);
  const { mutateAsync: checkIn, isLoading: isCheckingIn } = useCheckIn(selectedEventId);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModalAndReset = useCallback(() => {
    setIsModalOpen(false);
    setSelectedOrderUuid(undefined);
    setShowQRReader(true);
  }, [setIsModalOpen, setSelectedOrderUuid, setShowQRReader]);

  const onScannedOrder = useCallback(
    async (orderUuid: string) => {
      console.log('scanned: ' + orderUuid);
      console.log('selected : ' + selectedOrderUuid);

      // re-read same QR code
      if (orderUuid === selectedOrderUuid) {
        return;
      }

      setSelectedOrderUuid(orderUuid);

      try {
        const responseOrder = await getOrder(orderUuid);

        // already checked in
        if (responseOrder?.when_checked_in) {
          failureSound.play();
          toast.error('Already checked in!');
        } else {
          successSound.play();
        }

        setShowQRReader(false);
        setIsModalOpen(true);
      } catch (error: any) {
        console.error(error);
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        }
        toast.error('Not a valid order | QR code');
      }
    },
    [setSelectedOrderUuid, setShowQRReader, selectedOrderUuid, setIsModalOpen, getOrder]
  );

  const onCheckIn = useCallback(
    async () => {
      if (!selectedOrderUuid) {
        toast.error('Invalid QR code');
        return;
      }

      try {
        await checkIn(selectedOrderUuid);

        toast.success('Checked in!');

        closeModalAndReset();
      } catch (error: any) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        }
        toast.error('Error checking in.');
      }
    },
    [selectedOrderUuid, closeModalAndReset, checkIn, setSelectedOrderUuid]
  );

  return (
    <>
      <div>
        <Switch
          checked={showQRReader}
          onChange={() => setShowQRReader((prev) => !prev)}
          checkedChildren="camera"
          unCheckedChildren="camera"
          className="mx-auto"
        />
      </div>

      {showQRReader && (
        <>
          <QrScanner
            onDecode={(result) => {
              onScannedOrder(result);
            }}
            onError={(error) => { console.log(error?.message); }}
          />
        </>
      )}

      <span className="flex flex-col gap-2">
        <span className='text-md text-slate-400'>last_scan: {selectedOrderUuid ?? "N/A"}</span>
        <span className="text-xs text-slate-300 text-center">
          Note to volunteers: if you run into problems, talk to Arjun :)
        </span>
      </span>

      <Modal title={isLoadingOrderDetails || order === undefined ? <>...</> :
        (<span className='flex flex-row gap-2 items-center'>
          <span className={`${order?.when_checked_in ? "text-red-800" : "text-slate-800"} text-lg`}>
            {order?.memberName}
          </span>
          <span className={`text-slate-400 text-sm`}>
            {order?.phoneNumber}
          </span>
        </span>)
      } visible={isModalOpen}
        onCancel={closeModalAndReset}
        footer={
          <>
            {order?.when_checked_in ? (
              <Badge.Ribbon text={`ALREADY checked in ${moment(order?.when_checked_in).fromNow()}`} color="red">
              </Badge.Ribbon>
            )
              :
              (<div className="flex justify-end gap-3">
                <button onClick={closeModalAndReset} className="text-xs text-slate-400 rounded p-2">cancel</button>
                {isCheckingIn ? (
                  < Spin />)
                  :
                  <>
                    {
                      !order?.when_checked_in &&
                      <button onClick={onCheckIn} className="text-xs text-white p-2 rounded bg-teal-700">Check-in & continue</button>
                    }
                  </>
                }

              </div>
              )}
          </>
        }
      >
        <div className="flex flex-col gap-2">

          <span className="text-xs text-slate-300 text-center">
            scan id: {selectedOrderUuid}
          </span>
          <span className="text-xs text-slate-300 text-center">
            Ordered: {moment(order?.createdDate).fromNow()}
          </span>

          <Tag className='text-2xl' icon={<BiSolidDiamond />} color="grey">
            General ({order?.num_of_GA_tickets})
          </Tag>
          <Tag className='text-2xl' icon={<BiCurrentLocation />} color="gold">
            GOLD ({order?.num_of_GOLD_tickets})
          </Tag>
          <Tag className='text-2xl' icon={<BiSolidDiamond />} color="blue">
            DIAMOND ({order?.num_of_DIAMOND_tickets})
          </Tag>
        </div>
      </Modal>
    </>
  );
}

export default App;
