import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

export default function Login() {
  const [_authPassword, setAuthPassword, _logout] =
    useLocalStorage<string>('authPassword');

  const [inputPass, setInputPass] = useState<string>('');
  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputPass(event.target.value);
    },
    [setInputPass]
  );

  const navigate = useNavigate();

  const handleSubmit = useCallback(() => {
    if (inputPass !== 'rameeloBeast') {
      return;
    }

    setAuthPassword(inputPass);
    navigate('/');
  }, [inputPass, setAuthPassword]);

  return (
    <div className="container mx-auto p-10 flex flex-col justify-center gap-5">
      <input
        onChange={handleChangeInput}
        placeholder="insert admin password"
        value={inputPass}
        className={'p-3 bg-slate-200 shadow-lg w-full rounded'}
      />

      <button
        onClick={handleSubmit}
        className="bg-sky-500 shadow-xl p-3 rounded text-white"
      >
        Submit
      </button>
    </div>
  );
}
